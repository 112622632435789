/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 16, 2021, 10:53:18 PM
    Author     : db
*/
ion-item-group.table-border-all{
    border: solid;
    border-radius: 5px;
    border-width: thin;
    border-color: #f0f0f0;
    margin: 5px;    
}

ion-item-group.table-border-bottom-none{
    border: solid;
    border-radius: 5px;
    border-bottom: none;
    border-width: thin;
    border-color: #f0f0f0;

    margin: 5px;    
}

ion-item-group.table-border-bottom-for-mobile{
    border: solid;
    border-radius: 5px;
    border-bottom: none;
    border-width: thin;
    border-color: #f0f0f0;

    margin: 5px 5px 50px 5px;  
}

ion-item-group.min-table-with-border {
  height: 100%;
  border: thin solid #f0f0f0;
  border-radius: 5px;
  margin: none; 
}

ion-item-group.form-border-all {
    //border: solid;
    border: none;
    border-radius: 5px;
    border-width: thin;
    border-color: #f0f0f0;
    /*margin: 20px 10px 10px 10px;*/
    margin: 0px 0px 75px 3px;
    width: 100%;
    /*
    margin: 2% 10% 2% 17%;
    width: 60%;
    */
}    
    
ion-item-group.form-ion-input {
    border: solid;
    border-width: thin;
    margin: 10px 0px 10px 0px;
} 

/*
.ion-justify-content-center {
    padding: 0 0px 0 100px !important;
}  
*/
    
