/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 14, 2021, 11:45:05 PM
    Author     : db
*/

ion-item.no-border {
  --border-color: transparent;
  --highlight-color-focused: none;
  border: none;
}

ion-item.no-border-submit {
  --border-color: transparent;
  --highlight-color-focused: none;
  border: none;
}

.subheader {
 // --ion-item-background: #158943eb;
  //margin: -20px -10px 0px -25px;
  padding: 8px 10px 0 0;
  //height: 30px;
  font-size: medium;
  font-weight: bold;
  //text-align: center;
  //float: left;
  margin-left: 20px;
  min-width: 40%;
}