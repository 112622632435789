/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : June 1, 2024, 6:02 PM
    Author     : db
*/
@import './theme/common/common';

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

.no-scroll {
    --overflow: hidden;
}

.scroll {
    --overflow-y: scroll !important;
}

  .ion-color-primary {
    --background: #2d2491;
  }