/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 15, 2021, 1:01:21 AM
    Author     : db
*/
ion-tab-button.cust-tab{
   background-color: white;
   /*border-radius: 10px;*/
   /*margin: 30px 10px 30px 0px;*/
   /*padding: 0px;*/
   /*height: 50px;*/
    /*–color-selected: var(–ion-color-calm);*/
    /*font-size: small;*/
    
    /*border: 2px solid #f5f6f9;*/
    font-size: 18px;
    padding: 1px 0px 1px 0px;;
    height: 47px;
    color: #056728;
    font-weight: 600;
}

ion-tab-button.brand-tab{
  background-color: white;
  /*font-size: 16px;
  padding: 1px 10px 1px 0px;*/
  /*height: 35px;*/
  color: #056728;
  font-weight: bolder;
  margin-right: 1%;
  margin-left: -65px;
  font-size: 32px;
  color: #051a0d;
}

.main-icon {
  width: 30% !important;
  height: 70% !important;
}

.main-icon-mobile {
  width: 10% !important;
  height: 10% !important;
  float: left !important;
  margin-left: 15%;
  margin-right: 0px;
  padding-right: 0px;

}

ion-tab-button.cust-tab:hover {
  /*background-color:#f5f6f9;*/
  /*background-color: #f0f0f0;*/
  /*font-size: large;*/
  transform: scale(1.05);
  /*background-color: #d7d8da;*/
}

ion-tab-button.cust-tab:focus{
   background-color:#fbfcff;
   border-top-left-radius: 15px;
   border-top-right-radius: 15px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   /*background-color: #f0f2f0;*/
   /*background-color: #edf0ee;*/
   /*background-color: #f0f0f0;*/
   border-bottom: 1px solid #08073b;
}


/*ion-tab-button.tab-selected{*/
   /*background-color:#f5f6f9;*/
   /*background-color: #f0f2f0;*/
   /*background-color: #edf0ee;*/
   /*background-color: #f0f0f0;*/
    //border-top: 3px solid #3171e0;
   /* border-bottom: 1px solid #05530b;*/
    //border-left: 1px solid #d7d8da;
    //border-right: 1px solid #d7d8da;
/*}*/
