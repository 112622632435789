.no-underline a {
    text-decoration: none !important;
    margin-bottom: '5px';
}

.full-page{
    padding: 0; 
    margin: 0;
    width: 100%;
    font-size: 18px !important;
}

.full-page-mobile{
    padding: 0; 
    margin: 0;
    width: 100%;
    font-size: 16px !important;
}

.row-margin {
    margin-bottom: 1px;
}

.no-border-mobile {
    padding: 0px !important;
    margin-left: -10px !important;
}

.list-header-label {
    font-weight: bold;
    font-size: 14px !important;
}

.top-list-header-label {
    font-weight: bold;
    font-size: 18px !important;
    color: #051a0d !important;
}