/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 17, 2021, 3:29:16 PM
    Author     : db
*/
.main-header-title {
    color: #051a0d;
    font-size:xx-large;
    font-weight:bold;
    font-style: oblique;
    /*margin-left: -37px;*/
    padding: 10px 0 0 30px;
    //float:left;
    text-align: left;
    min-width: 100%;
}

.brand-name {
 background-color:#828b8708;
 //border-radius: 10px;
 //background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
 //background: linear-gradient(180deg, rgba(0, 255, 255, 0), #37857a);
 background: linear-gradient(360deg, rgba(0, 255, 255, 0), #b7cbc852);
 height:8%;
}

.header-subtitle {
  text-align: center;
  padding: 0 0 40px 0;
  --background: #158943eb;
  color: #000;
  font-weight:bold;
}

ion-title.main-footer-label{
  font-size: .85rem;
  font-family: system-ui;
  color: #051a0d !important;
  padding: 1px 80px 1px 80px;
  height: 47px;
  font-weight: 600;
  text-transform: capitalize;
  fill:"clear"!important;
}

ion-title.main-footer-label:hover {
  transform: scale(1.05);
  color: #051a0d !important;
}

ion-title.main-footer-label:focus{
   background-color:#fbfcff;
   border-top-left-radius: 15px;
   border-top-right-radius: 15px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
}


