/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 17, 2021, 2:04:18 PM
    Author     : db
*/
ion-toolbar.main-header-background {
   /* --background: #383a3e;*/
   
   --background: #158943eb;
   --min-height:60px!important;
}


ion-toolbar.custom-background{
    background-color: #FFF;
    width: 80%;
    height: 80px;
    margin-right: 2%;
    margin-left: 15%;
    flex-wrap: nowrap;
}


/*
ion-toolbar.custom-background{
    background-color: #FFF;
    justify-content: center;
    width: 70%;
    height: 50px;
    margin-right: 2%;
    margin-left: 14%;
    flex-wrap: nowrap;
    border-top: none;
}
    */