/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : June 1, 2024, 6:02 PM
    Author     : db
*/
.ion-label-contact{
    font-weight: bold !important;
    font-size: larger !important;
}

.contact-details{
    font-size: 20px !important;
}

.block-display {
    display: block;
    justify-content: center !important;
}


