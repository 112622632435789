/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 14, 2021, 11:57:49 PM
    Author     : db
*/

ion-tab-bar.custom-background{
    background-color: #FFF;
    justify-content: left;
    height: 50px;
    margin-right: 2%;
    margin-left: 11.3%;
    flex-wrap: wrap;
    border-bottom: none;
}

.inner-page{
    border-top: solid black !important;
    border-top-width: thick !important;
}

