.router-style {
    overflow-y: scroll; 
    max-height: 50%;
}

.footer-style {
    width:auto;
    border-top: solid;
    border-width: 0.1em;
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 10px;
}

.last {
    /*text-align: center;*/
    font-size: .85rem;
    font-family: system-ui;
    color: #051a0d !important;
    padding: 1px 0px 5px 0px;
    width: 80% !important;
    height: 20px;
    font-weight: 600;
    text-transform: capitalize;
    fill:"clear"!important;
}

.last-mobile {
    /*text-align: center;*/
    font-size: .65rem;
    font-family: system-ui;
    color: #051a0d !important;
    padding: 1px 0px 5px 0px;
    width: 80% !important;
    height: 20px;
    font-weight: 600;
    text-transform: capitalize;
    fill:"clear"!important;
}