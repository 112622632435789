/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : June 1, 2024, 6:02 PM
    Author     : db
*/

.ion-label-sectors{
    font-weight: bold !important;
    font-size: larger !important;
}

.ion-label-sectors-mobile{
    font-weight: bold !important;
    font-size: large !important;
}

.card-style {
    margin-top: 0px !important;
    padding-top: 0px !important;
}