/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 14, 2021, 11:49:36 PM
    Author     : db
*/

ion-list.no-last-border {
  :last-child {
    --border-style: none !important;
  }
}