.card {
    background-color: #99a3b5;
    color: #000000;
    font-weight: 200;
    font-size: medium;
    font-family: ui-sans-serif;
    padding: 1.2em;
}


.card-top-mobile-header {
  background-color: #99a3b5;
  padding: .5em;
  width:85%;
}

.mainMessage-icon-mobile {
  text-align: 'center';
  font-weight: 'bold !important';
  font-size: large;
  font-family: system-ui;
  color: #000000 !important;
  font-weight: 'bold';
  width: 'auto';
  float: left !important;
  margin-left: -10px;

}