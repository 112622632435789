/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 17, 2021, 3:38:06 PM
    Author     : db
*/
ion-button.main-header-button{
    color: #FFF;
    margin-left: -5px;
    font-size: large;
}

ion-button.new-button{
    margin-left: 15px;
    /*font-size: medium;*/
}

ion-button.change-product-button{
    margin-top: 35px;
}

ion-button.main-footer-label{
    font-size: .85rem;
    font-family: system-ui;
    color: #051a0d !important;
    padding: 1px 0px 5px 0px;
    width: 28% !important;
    height: 20px;
    font-weight: 600;
    text-transform: capitalize;
    fill:"clear"!important;
  }

  ion-button.mobile-footer-label{
    font-size: .65rem;
    font-family: system-ui;
    color: #051a0d !important;
    padding: 1px 0px 5px 0px;
    width: 28% !important;
    height: 20px;
    font-weight: 600;
    text-transform: capitalize;
    fill:"clear"!important;
  }

 ion-button.main-footer-label:hover {
    transform: scale(1.05);
    color: #051a0d !important;
  }
  
  ion-button.main-footer-label:focus{
     background-color:#fbfcff;
     border-top-left-radius: 15px;
     border-top-right-radius: 15px;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
  }
