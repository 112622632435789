/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 17, 2021, 11:29:13 PM
    Author     : Hiredtech2
*/

ion-modal.policy-design-modals {
    --width: 600px;
    --height: 196px;
}

