/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 16, 2021, 7:57:15 PM
    Author     : db
*/

ion-row.section-height{
   height: 25px;
}
