/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 26, 2021, 2:22:10 PM
    Author     : db
*/

.ion-label-sectors{
    font-weight: bold !important;
    font-size: small;
}

.centripet-main-message{

    text-align: center !important;

}

.block-display {
    display: block;
}

.item ion-thumbnail {
    min-width: 3rem;    
    min-height: 3rem;
    img {
        max-width: 2rem;    
        min-width: 2rem;
        border-radius: 10px;
    }
    
}

.button-style {
    max-width: 2rem;
    height: 2rem;
    margin-top: 13px;
}

.thumbnail-style {
    margin: 0px;
}

.service-label-mobile-style {
    font-size: small !important;
}