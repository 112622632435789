.service-details{
    font-size: 18px !important;
    justify-content: center;
    width: 100%;
}

.sub-title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 7%;
    font-size: larger;
    font-weight: 800
}

.back-button {
    padding-left: 0px !important;
    margin-left: -20% !important;
}