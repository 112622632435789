/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 17, 2021, 10:18:24 PM
    Author     : db
*/
ion-select.form-ion-select {
    border: thin solid #f5f6f9;
    margin: 10px 0px 10px 0px;
} 

