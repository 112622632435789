/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 26, 2021, 2:31:16 PM
    Author     : db
*/
.about-details{
    font-size: 18px !important;
}

.about-mission-details-processed{
    font-size: 18px !important;
    margin-left: 73px;
}

.about-details .about-heading{
    margin-right: 10px;
    width: 20px !important;
}

