/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 14, 2021, 11:50:31 PM
    Author     : db
*/

@import 'ion-item';
@import 'ion-list';
@import 'ion-tab-bar';
@import 'ion-tab-button';
@import 'ion-row';
@import 'ion-item-group';
@import 'ion-input';
@import 'ion-content';
@import 'ion-toolbar';
@import 'ion-title';
@import 'ion-button';
@import 'ion-tabs';
@import 'ion-select';
@import 'ion-modal';

.main-margins{
 margin: 5px 20px 0px 20px;
}

.header-md::after {  /*comment off to replace the default header background image*/
  background-image: none !important;
}

ion-content{
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 20px;   
}

.main-menu-label{
    padding: 0px 5px 0px 0px !important;
    font-size: 18px;
    font-family: system-ui;
}

/*
.brand-menu-label{
  padding: 0px 0px 0px 0px !important;
  font-size: 19px;
  color: #051a0d;
  font-size: xx-large;

  font-style: oblique;

  padding: 10px 0 0 30px;

  min-width: 400px;
}
*/

.brand-menu-label{
    /*padding: 0px 5px 0px 0px !important;*/
    /*font-size: 30px;*/
    /*font-size: xx-large;*/
    color: #051a0d;
    font-size: 33px;
    font-style: normal;
    /*width: 700% !important*/
}

.main-menu-icon{
    padding: 0px 5px 0px 5px !important;
    width: 100%;
    height: 100%;
}

