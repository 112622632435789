/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 17, 2021, 4:23:46 PM
    Author     : db
*/
ion-tabs.main-tab-content {
    margin-left: 20px;
}
